<template>
  <apexchart
    type="bar"
    width="100%"
    height="300"
    :options="chartOptions"
    :series="series"
    ref="chart"
  ></apexchart>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { format } from "@/mixins/format";
import moment from "moment";
export default {
  mixins: [format],
  components: {
    apexchart: VueApexCharts,
  },
  props: ["category", "lineData"],
  data() {
    return {
      moment: moment,
      series: [
        {
          name: "진단 성공률",
          data: this.lineData,
        },
      ],
      chartOptions: {
        chart: {
          type: "line",
          toolbar: {
            show: false,
            autoSelected: "zoom",
          },
        },
        stroke: {
          width: [1, 1, 4],
        },
        colors: ["#f7b140"],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(1) + "%";
          },
          style: {
            fontSize: "16px",
            colors: ["#191919"],
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            columnWidth: "30%",
            dataLabels: {
              position: "top",
            },
          },
        },
        markers: {
          size: 4,
        },
        xaxis: {
          categories: this.category,
        },
        yaxis: [
          {
            tickAmount: 5,
            axisTicks: {
              show: true,
            },
            max: 100,
            axisBorder: {
              show: false,
            },
            labels: {
              style: {
                colors: "#000",
              },
              show: false,
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
            title: {
              show: false,
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: false,
            position: "topLeft",
            offsetY: 30,
            offsetX: 60,
          },
        },
      },
    };
  },
  // computed: {
  //   shouldExtendWidth() {
  //     return this.chartOptions.xaxis.categories.length > 10;
  //   },
  // },
  watch: {
    lineData: function () {
      this.updateData();
    },
    category: function () {
      this.updateData();
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    updateData() {
      this.$refs.chart.updateOptions({
        series: [
          {
            name: "진단 성공률",
            data: this.lineData,
          },
        ],
        xaxis: {
          categories: this.category,
        },
      });
    },
  },
};
</script>
