<template>
  <div class="contents">
    <div class="title flexB">
      <h2>진단 이력 관리</h2>
    </div>
    <div class="box one">
      <h2>진단 성공률 차트</h2>
      <p class="flexR percent">
        진단 누적 성공률 : <span class="bold">{{ totalAvg }}%</span>
      </p>
      <div class="chartBox">
        <div :style="{ width: chartBoxWidth }">
          <DiagnosisChart :category="category" :lineData="lineData" />
        </div>
      </div>
    </div>
    <div class="box one">
      <h2 class="bold">진단 이력 리스트</h2>
      <table>
        <colgroup>
          <col style="width: 15%" />
          <col style="width: 20%" />
          <col style="width: 15%" />
          <col style="width: 50%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>진단 작물</th>
          <th>진단 성공률</th>
          <th>진단 시간</th>
        </tr>
        <tr v-for="(data, i) in diagnosisList" :key="i">
          <td>
            {{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}
          </td>
          <td>{{ data.crops }}</td>
          <td>{{ data.avg }}%</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm:ss") }}</td>
        </tr>
      </table>
      <div class="pagination" v-if="diagnosisList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <NoData v-else />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchAvg, fetchDiagnosisList } from "@/api/admin";
import { format } from "@/mixins/format";
import DiagnosisChart from "@/components/charts/DiagnosisChart";
import NoData from "@/components/contents/Nodata";
export default {
  mixins: [format],
  components: {
    DiagnosisChart,
    NoData,
  },
  name: "diagnosisManage",
  data() {
    return {
      moment: moment,
      date: "",
      inputMode: "",
      searchWord: "",
      diagnosisList: [],
      category: [],
      lineData: [],
      total: 0,
      totalAvg: "",
      currentPage: 1,
      isNodata: false,
      chartBoxWidth: "100%",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "2");
  },
  mounted() {
    this.getDiagnosisChart();
    this.getDiagnosisList();
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.diagnosisList = [];
      this.total = 0;
      this.currentPage = 1;
      this.getDiagnosisList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        return alert("검색 조건을 선택해 주세요.");
      }
      this.getDiagnosisList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDiagnosisList();
    },
    getDiagnosisChart() {
      let params = {};
      this.category = [];
      this.lineData = [];
      fetchAvg(params).then((res) => {
        if (res.data.status == 200) {
          this.category = res.data.data.dailyAvgs.dailyAvgs_x;
          this.lineData = res.data.data.dailyAvgs.dailyAvgs_y;
          this.totalAvg = res.data.data.totalAvg.toFixed(2);
          this.isNodata = this.total == 0 ? true : false;
          this.updateWidth(this.category ?? []);
        } else if (res.data.status == 409) {
          this.$router.push("/");
          return alert(res.data.message);
        } else {
          alert(res.data.message);
        }
      });
    },
    updateWidth(xaxis) {
      if (xaxis && xaxis.length > 0) {
        this.chartBoxWidth =
          xaxis.length > 10 ? `${180 * xaxis.length}px` : "100%";
      }
    },
    getDiagnosisList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
      };
      fetchDiagnosisList(params).then((res) => {
        if (res.data.status == 200) {
          this.diagnosisList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
